export const zhObj = {
  appName: '约会吧',
  lang: '中文',
  search: '搜索',
  switchLanguage: '切换语言',
  my: '我的',
  loginToView: '登录后才能查看我的哦',
  clickLogin: '点此登录',
  inReview: '审核中',
};
