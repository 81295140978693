import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { zhObj } from './lang/zh';
import { enObj } from './lang/en';

Vue.use(VueI18n); // 全局挂载

// 定义语言包
const messages = {
  en: {
    ...enObj,
  },
  zh: {
    ...zhObj,
  },
};

export const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh',
  messages,
});

export default i18n;
