export const enObj = {
  appName: 'Have a date',
  lang: 'en',
  search: 'Search',
  switchLanguage: 'Switch language',
  my: 'My',
  loginToView: 'You have to log in to see mine',
  clickLogin: 'Click here to login',
  inReview: 'in review',
  authenticated: 'Authenticated',
};
